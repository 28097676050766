import request from '@/utils/request';

//列表
export function companyInventoryList(data) {
  return request({
    url : '/companyInventory/companyInventoryList',
    method : 'post',
    data : data
  })
}

//新增
export function createCompanyInventory(data) {
  return request({
    url : '/companyInventory/createCompanyInventory',
    method : 'post',
    data : data
  })
}
//修改
export function updateCompanyInventory(data) {
  return request({
    url :'/companyInventory/updateCompanyInventory',
    method : 'post',
    data : data
  })
}


//删除
export function deleteCompanyInventory(data) {
  return request({
    url : '/companyInventory/deleteCompanyInventory',
    method : 'post',
    data : data
  })
}
//批量删除
export function batchDeleteCompanyInventory(data) {
  return request({
    url : '/companyInventory/batchDeleteCompanyInventory',
    method : 'post',
    data : data
  })
}
